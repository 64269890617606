import { DateTime } from 'luxon';
import { AnalysisTimeFrame, timeFrameToCadence } from '../../features/reports/models/analysis.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { getPeriods, getStartDate } from '../../features/reports/utils/helpers';
import { getLuxonKeyFromCadence, LuxonKey } from '../utils/key-translator';

export class CalendarSettings {
	direction?: DateRangeDirection;
	startDate?: string;
	endDate?: string;
	periods?: number;
	periodsOffset?: number;
	isRolling?: boolean;
	showWeekends?: boolean;
	disableWeekendSelector?: boolean;
	minDate?: string;
	maxDate?: string;
	toDateOption?: ToDateOption;
	customOptionCadence?: AnalysisTimeFrame;
	customOptionPeriods?: number;

	// reports settings
	rollingType?: RollingType;
	lastModifiedDate?: string;

	constructor(
		startDate?: string,
		endDate?: string,
		lastModifiedDate?: string,
		rollingType?: RollingType,
		toDateOption?: ToDateOption,
		showWeekends?: boolean,
		customOptionCadence?: AnalysisTimeFrame,
		customOptionPeriods?: number
	) {
		this.lastModifiedDate = lastModifiedDate;
		this.rollingType = rollingType;
		this.showWeekends = showWeekends;
		this.customOptionCadence = customOptionCadence;
		this.customOptionPeriods = customOptionPeriods;
		this.toDateOption = toDateOption;
		if ((startDate && endDate) || toDateOption) {
			this.getModifiedDates(startDate, endDate, toDateOption);
		}
	}

	private getModifiedDates?(startDate: string, endDate: string, toDateOption: ToDateOption): void {
		// gets the start and end date based on the ToDateOption
		if (toDateOption) {
			this.getToDateOption(toDateOption);
		} else if (this.customOptionCadence && this.customOptionPeriods) {
			const cadence: Cadence = timeFrameToCadence(this.customOptionCadence);
			this.endDate = DateTime.now().toFormat('yyyy-MM-dd');
			this.startDate = DateTime.fromJSDate(getStartDate(DateTime.now().startOf('day').toJSDate(), cadence, this.customOptionPeriods, !this.showWeekends))
				.startOf(<any>this.customOptionCadence)
				.toFormat('yyyy-MM-dd');
		} else {
			if (startDate) {
				// If rolling, uses the dates last modified date to determine the offset from given startDate, then calculates the correct start Date
				if (this.rollingType && (this.rollingType === RollingType.RollingBoth || this.rollingType === RollingType.RollingStart)) {
					this.startDate = this.getRollingDate(startDate);
				} else {
					this.startDate = startDate;
				}
				// this block of code adjusts the start date based on the show weekends value when the customOptionCadence is daily
				if (this.customOptionCadence === AnalysisTimeFrame.days) {
					// gets the amount of periods between start and end date regardless of showWeekends toggle when daily periods option is selected
					const realPeriods: number = getPeriods(DateTime.now(), DateTime.fromISO(this.startDate), 'daily', !this.showWeekends, true);
					// uses the realPeriods number to get the actual startDate, if show weekends is false, it will be adjusted here
					this.startDate = DateTime.fromJSDate(getStartDate(DateTime.now().toJSDate(), 'daily', realPeriods, !this.showWeekends)).toFormat('yyyy-MM-dd');
				}
			}
			if (endDate) {
				// If rolling, uses the dates last modified date to determine the offset from given endDate, then calculates the correct end Date
				if (this.rollingType && (this.rollingType === RollingType.RollingBoth || this.rollingType === RollingType.RollingEnd)) {
					this.endDate = this.getRollingDate(endDate);
				} else {
					this.endDate = endDate;
				}
			}
		}
		// this adjusts the start/end dates in the case where rolling start has met with a fixed end date, or rolling end date has gone longer than 740 periods
		if (this.rollingType === RollingType.RollingStart || this.rollingType === RollingType.RollingEnd) {
			this.fixRollingDates();
		}
	}

	getToDateOption?(toDateOption: ToDateOption): void {
		switch (toDateOption) {
			case ToDateOption.yesterday:
				let lStartDate: DateTime = DateTime.now().minus({ days: 1 });
				if (!this.showWeekends && (lStartDate.weekday === 6 || lStartDate.weekday === 7)) {
					if (lStartDate.weekday === 7) {
						lStartDate = lStartDate.minus({ days: 2 });
					} else if (lStartDate.weekday === 6) {
						lStartDate = lStartDate.minus({ days: 1 });
					}
				}
				this.startDate = lStartDate.toFormat('yyyy-MM-dd');
				break;
			case ToDateOption.wtd:
				this.startDate = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');
				break;
			case ToDateOption.mtd:
				this.startDate = DateTime.now().startOf('month').toFormat('yyyy-MM-dd');
				break;
			case ToDateOption.qtd:
				this.startDate = DateTime.now().startOf('quarter').toFormat('yyyy-MM-dd');

				break;
			case ToDateOption.ytd:
				this.startDate = DateTime.now().startOf('year').toFormat('yyyy-MM-dd');
				break;
		}
		this.endDate = toDateOption !== ToDateOption.yesterday ? DateTime.now().startOf('day').toFormat('yyyy-MM-dd') : this.startDate;
	}

	private getRollingDate?(ogDate: string): string {
		let newDate: string;
		const today: DateTime = DateTime.now().startOf('day');
		const ogSaveDate: DateTime = DateTime.fromISO(this.lastModifiedDate).startOf('day');
		const ogStartDate: DateTime = DateTime.fromISO(ogDate).startOf('day');
		const ogOffset: number = today.diff(ogSaveDate).as('days');
		if (ogOffset < 0) {
			newDate = ogStartDate.minus({ days: Math.abs(ogOffset) }).toFormat('yyyy-MM-dd');
		} else {
			newDate = ogStartDate.plus({ days: ogOffset }).toFormat('yyyy-MM-dd');
		}
		return newDate;
	}

	private fixRollingDates?(): void {
		const lStartDate: DateTime = DateTime.fromISO(this.startDate).startOf('day');
		const lEndDate: DateTime = DateTime.fromISO(this.endDate).startOf('day');
		if (this.rollingType === RollingType.RollingStart) {
			if (lStartDate > lEndDate) {
				this.startDate = this.endDate;
			}
		} else if (this.rollingType === RollingType.RollingEnd) {
			if (lEndDate.diff(lStartDate).as('days') > 740) {
				this.endDate = lStartDate.plus({ days: 740 }).toFormat('yyyy-MM-dd');
			}
		}
	}
}

export enum RollingType {
	RollingStart = 'rollingStart',
	RollingEnd = 'rollingEnd',
	RollingBoth = 'rollingBoth',
}

export enum CalendarType {
	Forecast = 'forecast',
	Reports = 'reports',
	transactions = 'tags',
	AuditLogs = 'auditLogs',
	Statement = 'statement',
}

export class DateRangePickerOption {
	id: string;
	displayText: string;
	periods: number;
	cadence: Cadence;
	direction: DateRangeDirection;
	duration: DatePickerDurations;
	toDate: boolean;
	excludeCurrentPeriod: boolean;

	constructor(
		periods: number,
		cadence: Cadence,
		direction: DateRangeDirection,
		duration?: DatePickerDurations,
		toDate?: boolean,
		excludeCurrentPeriod?: boolean
	) {
		this.periods = periods;
		this.cadence = cadence;
		this.direction = direction;
		this.toDate = toDate;
		this.duration = duration;
		this.excludeCurrentPeriod = excludeCurrentPeriod;

		if (toDate) {
			this.id = this.getToDateId();
		} else if (!duration) {
			this.id = periods + cadence + direction;
		} else {
			this.id = periods + duration + direction;
		}
		this.setDisplayText();
	}

	private setDisplayText(): string | void {
		if (this.periods > 1 && this.direction === DateRangeDirection.current) {
			throw new Error('Invalid date picker options');
		}

		let displayDirection: string = this.direction === DateRangeDirection.future ? 'Next' : 'Previous';

		if (this.toDate) {
			return (this.displayText = this.getToDateText());
		}

		if (this.duration && this.direction === DateRangeDirection.current) {
			displayDirection = 'Current';
		}
		let displayInterval: string = getLuxonKeyFromCadence(this.cadence);
		if (this.duration === DatePickerDurations.year) {
			displayInterval = LuxonKey.years;
		}
		if (!this.duration || this.periods > 1) {
			this.displayText = [displayDirection, this.periods, displayInterval].join(' ');
		} else {
			this.displayText = [displayDirection, displayInterval].join(' ').slice(0, -1);
		}
	}

	private getToDateId(): ToDateOption {
		switch (this.duration) {
			case DatePickerDurations.day:
				return ToDateOption.yesterday;
			case DatePickerDurations.week:
				return ToDateOption.wtd;
			case DatePickerDurations.month:
				return ToDateOption.mtd;
			case DatePickerDurations.quarter:
				return ToDateOption.qtd;
			case DatePickerDurations.year:
				return ToDateOption.ytd;
		}
	}

	private getToDateText(): string {
		switch (this.duration) {
			case DatePickerDurations.day:
				return 'Yesterday';
			case DatePickerDurations.week:
				return 'Week to Date';
			case DatePickerDurations.month:
				return 'Month to Date';
			case DatePickerDurations.quarter:
				return 'Quarter to Date';
			case DatePickerDurations.year:
				return 'Year to Date';
		}
	}
}

export enum DateRangeDirection {
	past = 'past',
	future = 'future',
	current = 'current',
}

export enum DatePickerDurations {
	day = 'day',
	week = 'week',
	month = 'month',
	quarter = 'quarter',
	year = 'year',
}

export enum ToDateOption {
	yesterday = 'ystrdy',
	wtd = 'wtd',
	mtd = 'mtd',
	qtd = 'qtd',
	ytd = 'ytd',
}

export enum DateRangeFocusedInput {
	startDate = 'startDate',
	endDate = 'endDate',
}

export function getDefaultDateRangePickerOptions(direction: DateRangeDirection): DateRangePickerOption[] {
	return [
		new DateRangePickerOption(7, Cadence.daily, direction),
		new DateRangePickerOption(30, Cadence.daily, direction),
		new DateRangePickerOption(13, Cadence.weekly, direction),
		new DateRangePickerOption(12, Cadence.monthly, direction),

		new DateRangePickerOption(1, Cadence.monthly, DateRangeDirection.current, DatePickerDurations.month),
		new DateRangePickerOption(1, Cadence.quarterly, DateRangeDirection.current, DatePickerDurations.quarter),
		new DateRangePickerOption(1, Cadence.quarterly, DateRangeDirection.current, DatePickerDurations.year),
	];
}

export function getReportPickerOptions(): DateRangePickerOption[] {
	return [
		new DateRangePickerOption(1, Cadence.daily, DateRangeDirection.past, DatePickerDurations.day, true),
		new DateRangePickerOption(1, Cadence.weekly, DateRangeDirection.past, DatePickerDurations.week, true),
		new DateRangePickerOption(1, Cadence.monthly, DateRangeDirection.past, DatePickerDurations.month, true),
		new DateRangePickerOption(1, Cadence.quarterly, DateRangeDirection.past, DatePickerDurations.quarter, true),
		new DateRangePickerOption(1, Cadence.quarterly, DateRangeDirection.past, DatePickerDurations.year, true),
	];
}

export function getStatementPickerOptions(): DateRangePickerOption[] {
	return [
		new DateRangePickerOption(1, Cadence.daily, DateRangeDirection.past, DatePickerDurations.day, true),
		new DateRangePickerOption(30, Cadence.daily, DateRangeDirection.past),
		new DateRangePickerOption(1, Cadence.weekly, DateRangeDirection.past, DatePickerDurations.week, false, true),
		new DateRangePickerOption(1, Cadence.monthly, DateRangeDirection.past, DatePickerDurations.month, false, true),
		new DateRangePickerOption(1, Cadence.quarterly, DateRangeDirection.past, DatePickerDurations.quarter, false, true),
	];
}

export function getReportsDefaultCalendarSettings(): CalendarSettings {
	const calendarSettings: CalendarSettings = new CalendarSettings();
	calendarSettings.direction = DateRangeDirection.past;
	calendarSettings.startDate = DateTime.now().minus({ days: 29 }).toFormat('yyyy-MM-dd');
	calendarSettings.endDate = DateTime.now().toFormat('yyyy-MM-dd');
	calendarSettings.showWeekends = true;
	calendarSettings.rollingType = RollingType.RollingBoth;
	calendarSettings.customOptionCadence = AnalysisTimeFrame.days;
	calendarSettings.customOptionPeriods = 30;
	calendarSettings.lastModifiedDate = DateTime.now().toFormat('yyyy-MM-dd');

	return calendarSettings;
}

export function getTransactionDefaultCalendarSettings(): CalendarSettings {
	const calendarSettings: CalendarSettings = new CalendarSettings();
	calendarSettings.direction = DateRangeDirection.past;
	calendarSettings.customOptionCadence = AnalysisTimeFrame.days;
	calendarSettings.showWeekends = true;
	return calendarSettings;
}

export function getStatementsDefaultCalendarSettings(): CalendarSettings {
	const calendarSettings: CalendarSettings = new CalendarSettings();
	calendarSettings.direction = DateRangeDirection.past;
	calendarSettings.showWeekends = true;
	return calendarSettings;
}
