import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReportV4, ReportV4ElementData, ReportV4Element, ElementType } from '../models/report.model';
import { Observable, throwError } from 'rxjs';
import { RequestThrottler } from '../utils/request-throttle';
import { DeleteReportResponse, GetReportsResponse } from '../models/report.model.api';
import { AnalysisDataSettings, AnalysisDisplaySettings } from '../models/analysis.model';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { DateTime } from 'luxon';
import { defaultCurrency } from '@trovata/app/shared/models/currency.model';

@Injectable({
	providedIn: 'root',
})
export class ReportsService extends RequestThrottler {
	constructor(private httpClient: HttpClient) {
		// max 2 concurrent requests for throttled endpoints
		super(2);
	}

	getReports(): Observable<HttpResponse<GetReportsResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v4/reports-list`;
		return this.httpClient.post<GetReportsResponse>(url, {}, { observe: 'response' }).pipe(
			map((response: HttpResponse<GetReportsResponse>) => {
				this.adjustReportCalendarSettings(response.body.reports);
				return response;
			})
		);
	}

	createReport(report: ReportV4): Observable<HttpResponse<ReportV4>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v4/reports`;
		return this.httpClient.post<ReportV4>(url, report, { observe: 'response' }).pipe(
			map((response: HttpResponse<ReportV4>) => {
				this.adjustReportCalendarSettings([response.body]);
				return response;
			})
		);
	}

	updateReport(report: ReportV4): Observable<HttpResponse<ReportV4>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v4/reports/${report.reportId}`;
		return this.httpClient.put<ReportV4>(url, report, { observe: 'response' }).pipe(
			map((response: HttpResponse<ReportV4>) => {
				this.adjustReportCalendarSettings([response.body]);
				return response;
			})
		);
	}

	getReportData({
		report,
		includeWeekends,
		startDate,
		endDate,
	}: {
		report: ReportV4;
		includeWeekends?: boolean;
		startDate?: string;
		endDate?: string;
	}): Observable<ReportV4ElementData[]> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v4/reports/` + report.reportId + '/data';
		const body: object = {};
		if (!(startDate && endDate)) {
			throw new Error('Start date and End date required');
		}
		if (startDate) {
			body['startDate'] = startDate;
		}
		if (endDate) {
			body['endDate'] = endDate;
		}

		body['excludeWeekends'] = includeWeekends === false;
		return new Observable<ReportV4ElementData[]>(observer => {
			const request: Observable<Promise<ReportV4ElementData[]>> = this.httpClient
				.post(url, body, {
					observe: 'response',
				})
				.pipe(
					map(async resp => {
						const reportData: ReportV4ElementData[] = resp.body['data'];
						observer.next(reportData);
						observer.complete();
						return reportData;
					}),
					catchError(err => {
						observer.error(err);
						observer.complete();
						return throwError(() => err);
					})
				);
			this.addRequestToQueue(request);
		});
	}

	/**
	 * @description Returns a S3 URL for the user to access their report
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public getReportPDF({
		report,
		startDate,
		endDate,
		trimWeekends,
		isGross,
	}: {
		report: ReportV4;
		startDate?: Date;
		endDate?: Date;
		trimWeekends: boolean;
		isGross?: boolean;
	}): Observable<HttpResponse<Object>> {
		const url = environment.trovataAPI('workspace') + '/export/reports/' + report.reportId + '/pdf';
		let params = new HttpParams();
		params = params.set('endDate', DateTime.fromJSDate(endDate, { zone: 'UTC' }).toISODate());
		params = params.set('startDate', DateTime.fromJSDate(startDate, { zone: 'UTC' }).toISODate());
		params = params.set('trimWeekends', trimWeekends.toString());
		if (isGross === true || isGross === false) {
			params = params.set('isGross', isGross.toString());
		}

		return this.httpClient
			.get(url, {
				params: params,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteReport(reportId: string): Observable<HttpResponse<DeleteReportResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v4/reports/${reportId}`;
		return this.httpClient.delete<DeleteReportResponse>(url, { observe: 'response' });
	}

	buildReport(dataSettings: AnalysisDataSettings, displaySettings: AnalysisDisplaySettings, calendarSettings: CalendarSettings, tqlJSON: Object): ReportV4 {
		const report: ReportV4 = new ReportV4();
		report.preferences = {};
		calendarSettings.lastModifiedDate = DateTime.local().toFormat('yyyy-MM-dd');

		const chartElement: ReportV4Element = {
			type: dataSettings.analysisType,
			parameters: {
				...dataSettings,
				tql: tqlJSON ? { type: 'AST', expression: tqlJSON } : null,
				currencyOverride: dataSettings.currencyOverride !== defaultCurrency.code ? dataSettings.currencyOverride : null,
				customMetric: dataSettings.customMetric,
			},
			preferences: {
				calendarSettings: calendarSettings,
				displaySettings: displaySettings,
				visualType: ElementType.chart,
				elementOrder: 0,
				// TODO should this default to something?
				balanceProperty: '',
			},
		};
		const gridElement: ReportV4Element = JSON.parse(JSON.stringify(chartElement));
		gridElement.preferences.elementOrder = 1;
		gridElement.preferences.visualType = ElementType.grid;
		report.elements = [chartElement, gridElement];
		return report;
	}

	private adjustReportCalendarSettings(reports: ReportV4[]): void {
		reports?.forEach((report: ReportV4) => {
			report.elements?.forEach((element: ReportV4Element) => {
				element.preferences.calendarSettings = new CalendarSettings(
					element.preferences.calendarSettings.startDate,
					element.preferences.calendarSettings.endDate,
					element.preferences.calendarSettings.lastModifiedDate,
					element.preferences.calendarSettings.rollingType,
					element.preferences.calendarSettings.toDateOption,
					element.preferences.calendarSettings.showWeekends,
					element.preferences.calendarSettings.customOptionCadence,
					element.preferences.calendarSettings.customOptionPeriods
				);
			});
		});
	}
}
